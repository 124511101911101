import { ComboBox, TextFieldReceiver } from "./combo-box";
import { IconPackage } from "./icon";

export type SearchLoadingHandler = (state: boolean) => void;
export type SearchHandler = (search: string, loading?: SearchLoadingHandler) => void;

export class Select<T extends object = any> extends ComboBox<T> {
    public descriptionField: TextFieldReceiver<T> | null = null;
    public iconField: TextFieldReceiver<T> | null = null;

    public getComponentName(): string {
        return "SelectComponent";
    }

    public search: SearchHandler | null = null;

    public clearable: boolean = false;

    public iconPackage: IconPackage | null = null;
    public icon: string | null = null;

    public reset(): void {
        this.items = [];
        this.selectedItem = null;
    }
}
