import { ComboBox } from "./combo-box";
import { SearchHandler } from "./select";

export class SuggestBox<T = any> extends ComboBox<T> {
    public text: string = "";
    public validation: string = "";

    public getComponentName(): string {
        return "SuggestBoxComponent";
    }

    public search: SearchHandler | null = null;
}
