import { VNode } from "vue";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BModal } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import { Modal2 } from "./modal-2";
import { VisibilityChangedEventArgs } from "..";
import { Modal } from "../modal/modal";

@Component({
    name: "modal-2-component",
    components: {
        BModal,
        ValidationObserver,
        ControlComponent: () => import("../controls/control.component"),
        ModalComponent: () => import("../modal/modal.component"),
    },
})
export class Modal2Component extends Vue {
    @Prop({
        type: String,
        required: false,
        default: "sm",
        validator: v => ["xs", "sm", "md", "lg", "xl", "fit"].includes(v),
    })
    private size!: "xs" | "sm" | "md" | "lg" | "xl" | "fit";

    @Prop({ type: Boolean, required: false, default: false })
    private centered!: boolean;

    @Prop({ type: Object, required: true })
    private handler!: Modal2;

    private get validatorId(): string {
        return `${this.handler.id}-validator`;
    }

    private get sizeClass(): string {
        return `modal-${this.size}`;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    get scopedSlots() {
        const footer = this.handler.footer ?? false;

        if (!footer) {
            return {};
        }

        return {
            "modal-footer": footer,
        };
    }

    @Watch("handler")
    private onHandlerChanged(): void {
        this.init();
    }

    public mounted(): void {
        this.init();
    }

    private init(): void {
        this.handler.addVisibleChangedHandler(this.onModalVisibleChanged.bind(this));
        this.handler.addValidateHandler(this.onModalValidate.bind(this));
    }

    private close(): void {
        this.handler.hide();
    }

    private onModalVisibleChanged(sender: any, e: VisibilityChangedEventArgs): void {
        if (e.visible) {
            this.$bvModal.show(this.handler.id);
        } else {
            this.$bvModal.hide(this.handler.id);
        }
    }

    private async onModalValidate(sender: any, e: any): Promise<void> {
        e.valid = await (this.$refs[this.validatorId] as any).validate();
    }

    public render(): VNode {
        return (
            <div>
                <b-modal
                    id={this.handler.id}
                    size=""
                    dialog-class={this.sizeClass}
                    title={this.handler.title.toUpperCase()}
                    scrollable={this.handler.scrollable && !!this.handler.footer}
                    hide-footer={!this.handler.footer}
                    contentClass="shadow"
                    centered={this.centered}
                    scopedSlots={{
                        "modal-footer": () => <control-component handler={this.handler.footer} />,
                    }}
                    v-on:hide={this.close}
                >
                    <validation-observer ref={this.validatorId}>
                        {this.handler.controls.map(control => (
                            <control-component handler={control} class="mb-0.75" />
                        ))}
                    </validation-observer>
                </b-modal>

                {this.renderSubmodals()}
            </div>
        );
    }

    private renderSubmodals(): VNode[] {
        const controls: VNode[] = [];
        for (const submodal of this.handler.submodals) {
            if (submodal instanceof Modal2) {
                controls.push(<modal-2-component size={submodal.size} handler={submodal} />);
            } else if (submodal instanceof Modal) {
                controls.push(<modal-component handler={submodal} />);
            }
        }
        return controls;
    }
}
