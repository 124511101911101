import { VNode } from "vue";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BModal } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import { Modal } from "./modal";
import { VisibilityChangedEventArgs } from "..";

@Component({
    name: "modal-component",
    components: {
        BModal,
        ValidationObserver,
        ControlComponent: () => import("../controls/control.component"),
    },
})
export class ModalComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Modal;

    private get validatorId(): string {
        return `${this.handler.id}-validator`;
    }

    @Watch("handler")
    private onHandlerChanged(): void {
        this.init();
    }

    public mounted(): void {
        this.init();
    }

    private init(): void {
        this.handler.addVisibleChangedHandler(this.onModalVisibleChanged.bind(this));
        this.handler.addValidateHandler(this.onModalValidate.bind(this));
    }

    private close(): void {
        this.handler.hide();
    }

    private onModalVisibleChanged(sender: any, e: VisibilityChangedEventArgs): void {
        if (e.visible) {
            this.$bvModal.show(this.handler.id);
        } else {
            this.$bvModal.hide(this.handler.id);
        }
    }

    private async onModalValidate(sender: any, e: any): Promise<void> {
        e.valid = await (this.$refs[this.validatorId] as any).validate();
    }

    public render(): VNode {
        return (
            <div>
                <b-modal
                    id={this.handler.id}
                    size={this.handler.size}
                    //title={this.handler.title.toUpperCase()}
                    content-class="shadow"
                    hide-footer
                    v-on:hide={this.close}
                    scopedSlots={{
                        "modal-title": () => this.handler.titleVNode ?? this.handler.title.toUpperCase(),
                    }}
                >
                    <validation-observer ref={this.validatorId}>{this.renderControls()}</validation-observer>
                </b-modal>

                {this.renderSubmodals()}
            </div>
        );
    }

    private renderControls(): VNode[] {
        const controls: VNode[] = [];
        for (const control of this.handler.controls) {
            if (!control.visible) {
                continue;
            }

            controls.push(<control-component handler={control} class="mb-0.75" />);
        }
        return controls;
    }

    private renderSubmodals(): VNode[] {
        const controls: VNode[] = [];
        for (const submodal of this.handler.submodals) {
            controls.push(<modal-component handler={submodal} />);
        }
        return controls;
    }
}

export default ModalComponent;
