import { FieldFormat } from "@lib";
import { ComboBox, TextFieldReceiver } from "./combo-box";
import { TextChangedEventArgs } from "./text-box";
import { EventHandler } from "..";

export type AutoSuggestSearchHandler = (search: string) => void | Promise<void>;

export class AutoSuggest<T = any> extends ComboBox<T> {
    public name: string = "";
    public format: FieldFormat | null = null;
    public validation: string | null = null;
    public limit = 10;

    public descriptionField: TextFieldReceiver<T> | null = null;
    public search: AutoSuggestSearchHandler = () => {};

    public getComponentName(): string {
        return "AutoSuggestComponent";
    }

    private _text: string = "";
    public get text(): string {
        return this._text;
    }
    public set text(text: string) {
        if (text === this._text) {
            return;
        }

        this._text = text;
        this.notifyTextChangedHandlers();
    }

    private _textChangedHandlers = new Set<EventHandler<TextChangedEventArgs>>();
    public addTextChangedHandler(handler: EventHandler<TextChangedEventArgs>): void {
        this._textChangedHandlers.add(handler);
    }
    public removeTextChangedHandler(handler: EventHandler<TextChangedEventArgs>): void {
        this._textChangedHandlers.delete(handler);
    }
    private notifyTextChangedHandlers(): void {
        const args: TextChangedEventArgs = { text: this._text };
        for (const handler of this._textChangedHandlers) {
            handler(this, args);
        }
    }
}
